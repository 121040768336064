<template>
  <div class="form">
    <h1 class="h3 font-weight-bold mb-6">
      {{ $t('title') }}
    </h1>
    <p
      class="mb-8"
      v-html="$sanitizeHtml($t('subtitle', { email: email }))"/>
    <router-link
      :to="{ name: 'login' }"
      class="cy-link">
      {{ $t('returnLogin') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CyPageMfaLoginLinkNotice',
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.MFALoginLinkNotice',
        subtitle: `We've sent you a temporary login link. Please check your inbox at <strong>{email}</strong>.`,
        returnLogin: 'Return to Login Page',
        alert: 'Login link sent',
      },
      es: {
        title: '@:routes.MFALoginLinkNotice',
        subtitle: `Te hemos enviado un enlace de inicio de sesión temporal. Por favor, revisa tu bandeja de entrada en <strong>{email}</strong>.`,
        returnLogin: 'Volver a la página de inicio de sesión',
        alert: 'Enlace de inicio de sesión enviado',
      },
      fr: {
        title: '@:routes.MFALoginLinkNotice',
        subtitle: `Nous vous avons envoyé un lien de connexion temporaire. Veuillez vérifier votre boîte de réception à l'adresse <strong>{email}</strong>.`,
        returnLogin: 'Revenir sur la page de connexion',
        alert: 'Lien de connexion envoyé',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin: auto;
  text-align: center;
}
</style>
